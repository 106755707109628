import React, { Component } from "react";
import { Provider } from "mobx-react";
import stores from "./stores";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import AppContainer from "./containers/AppContainer";

const browserHistory = createBrowserHistory();
const routeStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routeStore);

export default class Root extends Component {
  render() {
    return (
      <Provider store={stores} routingStore={routeStore}>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <AppContainer />
          </Router>
        </I18nextProvider>
      </Provider>
    );
  }
}
