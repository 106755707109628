import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Button } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCog,
  faUser,
  faUserLock,
  faGlobe,
  faPlus,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Router from "../Router";
import "./AppContainer.css";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { storage } from "../utils";
import { observe } from "mobx";
import { GA_TRACKING_ID, initialize } from "../settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddAdModalComponent from "../components/app/AddAdModalComponent";
import AppFooter from "../components/app/AppFooter";
import SelectPlaceModalComponent from "../components/app/SelectPlaceModalComponent";
import ReactGA from "react-ga";
import PhoneRequiredModalComponent from "../components/app/PhoneRequiredModalComponent";
import "moment/locale/ru";
import "moment/locale/ky";
import moment from "moment";
import DownloadAppModalComponent from "../components/app/DownloadAppModalComponent";
//import PageNotFoundScreen from "../screens/PageNotFoundScreen";
import { Helmet } from "react-helmet";

library.add(fab);

const styles = {
  containerWithAd: {
    width: 1000,
    marginBottom: 50,
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

@withTranslation()
@withRouter
@inject("store")
@inject("routingStore")
@observer
class AppContainer extends Component {
  constructor(props) {
    super(props);
    initialize(this.props.store.appStore);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.onDeviceChange = this.onDeviceChange.bind(this);
    this.brandingHandler = this.brandingHandler.bind(this);
    this.showAddAdModal = this.showAddAdModal.bind(this);

    this.categoryStore = this.props.store.categoryStore;
    this.favoriteStore = this.props.store.favoriteStore;
    this.placeStore = this.props.store.placeStore;
    this.adStore = this.props.store.adStore;
    this.categoryStore.useStorage(storage);
    this.placeStore.useStorage(storage);
    this.store = this.props.store.appStore;
    this.store.useI18n(this.props.i18n);
    this.store.useStorage(storage);

    this.routingStore = this.props.routingStore;

    observe(this.store, "branding", this.brandingHandler);
    observe(this.store.messages, this.onMessage);
    observe(this.store, "device", this.onDeviceChange);

    observe(
      this.store,
      "language",
      ({ newValue }) => {
        moment.locale(newValue);
      },
      true
    );

    this.initialState = { showAddAdModal: false };

    this.state = this.initialState;

    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.set({ userId: 0 });
  }

  onLocationChange = (location = null, action = null) => {
    if (!location) location = this.routingStore.history.location;
    //if (!action) action = this.routingStore.history.action;
    window.scrollTo(0, 0);
    // if (
    //   !location.key &&
    //   routers.filter((v) => location.pathname.startsWith(v.path)).length === 0
    // )
    //   this.routingStore.replace("/");
    if (location) this.store.setRouteKey(location.key);

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  };

  updateDimensions() {
    let width = window.innerWidth;
    if (width >= 1050) this.store.setDevice("desktop");
    else if (width >= 768) this.store.setDevice("tablet");
    else this.store.setDevice("mobile");
  }

  onDeviceChange({ newValue }) {
    if (newValue === "desktop") {
      this.setBrandingBanner(this.store.branding);
    } else {
      this.setBrandingBanner(null);
    }
  }

  showAddAdModal() {
    if (this.store.authenticated) {
      this.store.setShowAddAdModal(true);
    } else {
      this.props.routingStore.push("/auth");
      this.store.showWarning(
        this.props.t("toast.warning.authorizeToUseThisComponent")
      );
    }
  }

  componentWillMount() {
    this.updateDimensions();
    this.store.checkAuth();
    this.categoryStore.getAllItems();
    this.placeStore.fetchAllItems();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this.onLocationChange();
    window.addEventListener("resize", this.updateDimensions);
    // this.store.setBranding({
    //   url: "https://instagram.com/mommy.baby.kg",
    //   backgroundColor: "#eeeeeee",
    //   height: "150px",
    //   backgroundImage: "/images/001.jpg",
    // });
    this.props.history.listen((...props) => this.onLocationChange(...props));
  }

  setBrandingBanner(newValue) {
    if (newValue != null) {
      if (newValue.backgroundColor)
        document.body.style["background-color"] = newValue.backgroundColor;
      if (newValue.backgroundImage)
        document.body.style[
          "background-image"
        ] = `url(${newValue.backgroundImage})`;
    } else {
      document.body.style["background-color"] = "#fff";
      document.body.style["background-image"] = null;
    }
  }

  brandingHandler(nv) {
    if (this.store.device !== "desktop") return;
    let { newValue } = nv;
    this.setBrandingBanner(newValue);
  }

  changeLanguage = (e) => {
    e && e.preventDefault();
    this.store.setLanguage(this.store.language === "ru" ? "ky" : "ru");
    return false;
  };

  onMessage(newValue) {
    if (newValue.addedCount < 1) return;
    const toastr = { ...toast, danger: toast.error, warning: toast.warn };
    for (let m of newValue.added) {
      let { message, level } = m;
      if (message && level && toastr[level]) {
        toastr[level](message);
      }
    }
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>
            SOM.KG бесплатные объявления Кыргызстан Акысыз жарнамалар
          </title>
        </Helmet>

        <SelectPlaceModalComponent
          item={this.store.place}
          for_filter={this.store.placeModalForFilter}
          language={this.store.language}
          adStore={this.adStore}
          items={this.placeStore.items}
          show={this.store.showPlaceModal}
          setPlace={(p) => this.store.setPlace(p)}
          setValueForAppStore={(n, v) => this.store.setValue(n, v)}
        />

        <DownloadAppModalComponent t={t} store={this.store} />

        <AddAdModalComponent />
        {this.store.authenticated && <PhoneRequiredModalComponent />}
        {this.store.device === "desktop" && this.store.branding && (
          <a href={this.store.branding.url} target="_blank">
            <div
              style={{
                width: "1000px",
                height: this.store.branding.height
                  ? this.store.branding.height
                  : "250px",
              }}
            />
          </a>
        )}
        <div
          className={`${
            this.store.device === "desktop" ? "container" : ""
          } shadow-lg rounded`}
          style={
            this.store.device === "desktop"
              ? styles.containerWithAd
              : { width: "100%" }
          }
        >
          <div className="px-4 pt-4 d-none d-md-flex justify-content-md-between align-items-md-center">
            <Link to="/">
              <img
                alt="Som.kg"
                width={"124px"}
                src={"/images/logo-684x301.png"}
              />
            </Link>
            <div style={{ lineHeight: "51px" }}>
              {this.store.is_admin && (
                <Link to="/admin">
                  <FontAwesomeIcon icon={faCog} />
                </Link>
              )}
              <a href="#" className={"ml-3"} onClick={this.changeLanguage}>
                <FontAwesomeIcon icon={faGlobe} /> {t("other.changeLanguage")}
              </a>
              {this.store.authenticated || this.store.authenticating ? null : (
                <Link className={"ml-3"} to="/auth">
                  <FontAwesomeIcon icon={faUserLock} /> Авторизация
                </Link>
              )}
              {this.store.authenticated && !this.store.authenticating && (
                <Link className={"ml-3"} to="/profile">
                  <FontAwesomeIcon icon={faUser} /> Профиль
                </Link>
              )}
            </div>
            <div style={{ lineHeight: "51px" }}>
              <Button
                variant="danger"
                size="sm"
                disabled={this.store.authenticating}
                onClick={this.showAddAdModal}
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth />{" "}
                {t("buttonLabel.addAd")}
              </Button>
            </div>
          </div>
          <div className="p-3 d-block d-sm-block d-xl-none d-md-none d-lg-none">
            <div className={"d-flex justify-content-start"}>
              <Link to="/">
                <img
                  width={"124px"}
                  alt="Som.kg"
                  src={"/images/logo-684x301.png"}
                />
              </Link>
              <div className={"ml-4"}>
                {this.store.is_admin && (
                  <div>
                    <Link to="/admin">
                      <FontAwesomeIcon icon={faCog} /> {t("linkLabel.admin")}
                    </Link>
                  </div>
                )}
                <div className={"mt-1"}>
                  <a href="#" onClick={this.changeLanguage}>
                    <FontAwesomeIcon icon={faGlobe} />{" "}
                    {t("other.changeLanguage")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {this.store.authenticated && (
            <div className="App-navbar mt-md-2 d-none d-sm-none d-xl-flex justify-content-xl-between d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between">
              <div>
                <Link to="/favorites">
                  <FontAwesomeIcon icon={faHeart} /> {t("linkLabel.favorites")}
                </Link>
                {/*<Link to="/tariffs"><FontAwesomeIcon icon={faReceipt} /> {t("linkLabel.tariffs")}</Link>*/}
              </div>
              <div>
                <Link className={"ml-3"} to="/profile">
                  <FontAwesomeIcon icon={faUser} /> {this.store.user.full_name}
                </Link>
              </div>
            </div>
          )}
          <div className="App-navbar d-md-none p-2">
            <div>
              {/*<Link to="/contacts"><FontAwesomeIcon icon={faPhoneAlt} /> {t("linkLabel.contacts")}</Link>*/}
              {this.store.authenticated || this.store.authenticating ? null : (
                <Link to="/auth">
                  <FontAwesomeIcon icon={faUserLock} /> Авторизация
                </Link>
              )}
              {this.store.authenticated && !this.store.authenticating && (
                <Link to="/profile">
                  <FontAwesomeIcon icon={faUser} /> Профиль
                </Link>
              )}
              {this.store.authenticated && (
                <Link to="/favorites">
                  <FontAwesomeIcon icon={faHeart} /> {t("linkLabel.favorites")}
                </Link>
              )}
            </div>
            <div className={"text-center"}>
              <Button
                size={"sm"}
                variant="danger"
                disabled={this.store.authenticating}
                onClick={this.showAddAdModal}
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth />{" "}
                {t("buttonLabel.addAd")}
              </Button>
            </div>
          </div>
          <div className="App-content">
            <Router />
            {/*!this.store.route_key && <PageNotFoundScreen />*/}
          </div>
          <AppFooter />
        </div>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      </React.Fragment>
    );
  }
}

export default AppContainer;
